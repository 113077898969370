import React, { useEffect, useState } from "react";
import Typewriter from "typewriter-effect";
import Slider from "react-slick";
import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useLocation } from "react-router-dom";
import Navigation from "./Navigation";

const Home = () => {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Allow infinite looping
    speed: 1000, // Transition speed in milliseconds
    slidesToShow: 1.09, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at a time
    centerMode: true,
    centerPadding: "7.5%",
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000,
    arrows: false,
  };

  const options = {
    dots: true, // Show navigation dots
    infinite: true, // Allow infinite looping
    speed: 1000, // Transition speed in milliseconds
    slidesToShow: 5, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at a time
    centerMode: true,
    centerPadding: "0%",
    swipeToSlide: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1500,
    arrows: false,
  };

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetElement = document.querySelector(location.hash);
      if (targetElement) {
        setTimeout(() => {
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    }
  }, [location]);

  // const [index, setIndex] = useState(0);

  // const handleSelect = (selectedIndex) => {
  //   setIndex(selectedIndex);
  // };
  return (
    <React.Fragment>
      <div>
        {/* light-dark mode button */}
        {/* START  NAVBAR */}
        <Navigation />
        {/* END NAVBAR */}
        {/* home section */}
        <section className="home-2 bg-primary" id="home">
          <div className="background-line">
            <div className="line-rounded" />
          </div>
          {/* start container */}
          <div className="container position-relative">
            {/* start row */}
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-8">
                <div className="text-center margin-top-120">
                  <h1 className="home-title mb-0 text-white display-5">
                    <Typewriter
                      className="typewrite"
                      data-period={2000}
                      options={{
                        strings: [
                          "Rivoluziona i tuoi sondaggi",
                          "Esprimi la tua voce in modo innovativo",
                          "Coinvolgi come mai prima d’ora",
                        ],
                        autoStart: true,
                        loop: true,
                      }}
                    >
                      <span className="wrap" />
                    </Typewriter>
                  </h1>
                  <p className="text-white-50 mt-4">
                    Benvenuto su Votiamo.com—l’app di sondaggi rivoluzionaria
                    che trasforma il modo in cui raccogli opinioni, preferenze e
                    feedback. Progettata in modo intuitivo, Votiamo offre
                    un’esperienza di voto senza precedenti che ti permette di
                    esprimere la tua voce in modi innovativi e coinvolgen ti.
                    Scopri le nostre funzionalità uniche e scopri come
                    sfruttarle al meglio.
                  </p>
                </div>
              </div>
            </div>
            {/* end row */}
            <div className="phone-mockup">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-3">
                  <img src="images/home/01.png" alt="" className="img-fluid" />
                </div>
                <div className="col-lg-4">
                  <div className="video-preview video-container mt-4">
                    <img
                      src="images/home/02.png"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="watch-video">
                      {/* <a
                        href
                        className="video-play-icon watch text-white"
                        data-bs-toggle="modal"
                        data-bs-target="#watchvideomodal"
                      >
                        <i className="mdi mdi-play text-center d-inline-block rounded-pill text-light bg-primary fs-30 avatar-lg me-1" />
                      </a> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <img src="images/home/03.png" alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          {/* end container */}
          <div className="background-line-2">
            <div className="line-rounded" />
          </div>
          <div
            className="modal fade bd-example-modal-lg"
            id="watchvideomodal"
            data-keyboard="false"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog modal-lg">
              <div className="modal-content hero-modal-0 bg-transparent">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                <video id="VisaChipCardVideo" className="w-100" controls>
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                  />
                  {/*Browser does not support <video> tag */}
                </video>
              </div>
            </div>
          </div>
        </section>
        {/* end home section */}
        {/* service section */}
        <section
          className="section service home2-service bg-light"
          id="service"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="title text-center mb-5">
                  <h6 className="mb-0 fw-bold text-primary">Cosa Facciamo</h6>
                  <h2 className="f-40">Come Possiamo Aiutarti</h2>
                  <div className="border-phone">
                    <p className="text-muted">
                      Su Votiamo, trasformiamo il modo in cui interagisci con il
                      tuo pubblico fornendo strumenti innovativi che rendono la
                      raccolta di opinioni e feedback semplice e interattiva. La
                      nostra piattaforma ti consente di creare sondaggi
                      efficaci, connetterti con gli altri e offrire esperienze
                      gratificanti che beneficiano tutti i coinvolti.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-lg-4">
                <div className="service-box text-center">
                  <div
                    className="service-icon p-4"
                    style={{
                      backgroundImage: "url(./images/service/bomb.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <i className="mdi mdi-security text-gradiant f-30" />
                  </div>
                  <div className="service-content mt-4">
                    <a href>
                      <h5 className="fw-bold">Crea Sondaggi</h5>
                    </a>
                    <p className="text-muted">
                      Progetta e lancia facilmente sondaggi personalizzati adat
                      tati alle tue specifiche esigenze. Con la nostra
                      interfaccia intuitiva, puoi creare sondaggi a scelta
                      singola, multipla o con valutazione a 5 stelle in pochi
                      minuti. Raggiungi il tuo pubblico target, raccogli
                      preziose informazioni e prendi decisioni informate basate
                      su dati in tempo reale.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 pt-4 pt-lg-0">
                <div className="service-box text-center shadow">
                  <div
                    className="service-icon p-4"
                    style={{
                      backgroundImage: "url(./images/service/bomb.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <i className="mdi mdi-lightbulb-on text-gradiant f-30" />
                  </div>
                  <div className="service-content mt-4">
                    <a href>
                      <h5 className="fw-bold"> Crea Connessioni</h5>
                    </a>
                    <p className="text-muted">
                      Crediamo nel potere della comunità e delle prospettive
                      condivise. Votiamo permette ai partecipanti di incontra re
                      e interagire con altri che condividono opinioni simili.
                      Attraverso la nostra piattaforma, puoi favorire
                      discussioni significative, costruire relazioni ed
                      espandere la tua rete connettendoti con individui affini.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 pt-4 pt-lg-0">
                <div className="service-box text-center">
                  <div
                    className="service-icon p-4"
                    style={{
                      backgroundImage: "url(./images/service/bomb.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <i className="mdi mdi-google-nearby text-gradiant f-30" />
                  </div>
                  <div className="service-content mt-4">
                    <a href>
                      <h5 className="fw-bold">Vinci Premi</h5>
                    </a>
                    <p className="text-muted">
                      Aumenta il coinvolgimento offrendo ai tuoi partecipanti
                      ricompense entusiasmanti. Motiva il tuo pubblico con la
                      possibilità di vincere premi tramite estrazioni casuali. È
                      il nostro modo di rendere l’esperienza del sondaggio non
                      solo istruttiva ma anche piacevole e gratificante per
                      tutti i coinvolti.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end section */}
        {/* start features */}
        <div className="section features" id="features">
          {/* start container */}
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="title text-center mb-5">
                  <h6 className="mb-0 fw-bold text-primary">
                    Caratteristiche di AppTech
                  </h6>
                  <h2 className="f-40"> Funzionalità della Nostra App</h2>
                  <p className="text-muted">
                    Scopri come Votiamo trasforma l'esperienza dei sondaggi con
                    funzionalità innovative progettate per rendere la creazione
                    e la partecipazione ai sondaggi semplice e coinvolgente.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <ul
                  className="nav nav-pills mb-3 justify-content-center"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item mb-3" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Funzionalità Principali
                    </button>
                  </li>
                  <li className="nav-item mb-3" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Funzionalità Smart
                    </button>
                  </li>
                </ul>
                <div className="tab-content mt-5" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row align-items-center">
                      <div className="col-lg-4 order-2 order-lg-first">
                        <div className="features-item text-start text-lg-end">
                          <div className="icon avatar-sm text-center ms-lg-auto rounded-circle">
                            <i className="mdi mdi-message-alert-outline f-24" />
                          </div>
                          <div className="content mt-3">
                            <h5>Creazione di Sondaggi Versatili</h5>
                            <p>
                              Crea sondaggi a scelta singola, multipla o con
                              valutazione a 5 stelle, personalizzati per le tue
                              esigenze.
                            </p>
                          </div>
                        </div>
                        <div className="features-item text-start text-lg-end mt-5">
                          <div className="icon avatar-sm text-center ms-lg-auto rounded-circle">
                            <i className="mdi mdi-autorenew f-24" />
                          </div>
                          <div className="content mt-3">
                            <h5> Inviti Personalizzati</h5>
                            <p>
                              Invita facilmente i partecipanti caricando un file
                              Excel con i tuoi contatti.
                            </p>
                          </div>
                        </div>
                        <div className="features-item text-start text-lg-end mt-5 mb-5">
                          <div className="icon avatar-sm text-center ms-lg-auto rounded-circle">
                            <i className="mdi mdi-eyedropper f-24" />
                          </div>
                          <div className="content mt-3">
                            <h5>Analisi Avanzate</h5>
                            <p>
                              Ottieni analisi dettagliate filtrando i risultati
                              per età, genere, città e altre caratteristiche
                              personalizzate.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <img
                          src="images/home/01.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-lg-4 order-last">
                        <div className="features-item">
                          <div className="icon avatar-sm text-center rounded-circle">
                            <i className="mdi mdi-lifebuoy f-24" />
                          </div>
                          <div className="content mt-3">
                            <h5>Risultati in Tempo Reale</h5>
                            <p>
                              Monitora gli esiti dei sondaggi in tempo reale per
                              un feedback immediato e decisioni rapide.
                            </p>
                          </div>
                        </div>
                        <div className="features-item mt-5">
                          <div className="icon avatar-sm text-center rounded-circle">
                            <i className="mdi mdi-dropbox f-24" />
                          </div>
                          <div className="content mt-3">
                            <h5> Sondaggi Pubblici e Privati</h5>
                            <p>
                              {" "}
                              Scegli tra sondaggi aperti a tutti o restringi
                              l'accesso solo ai parteci panti invitati.
                            </p>
                          </div>
                        </div>
                        <div className="features-item mt-5">
                          <div className="icon avatar-sm text-center rounded-circle">
                            <i className="mdi mdi-flask f-24" />
                          </div>
                          <div className="content mt-3">
                            <h5>Community</h5>
                            <p>
                              Crea comunità di persone che hanno a cuore lo stesso obiettivo.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <img
                          src="images/features/phone2.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-lg-6">
                        <h2 className="mb-4">Funzionalità Smart</h2>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="features-box mt-4">
                              <div className="d-flex">
                                <div className="icon">
                                  <i className="mdi mdi-check-circle f-20 me-2" />
                                </div>
                                <div className="heading">
                                  <h6 className="mt-1">
                                    Partecipazione Anonima
                                  </h6>
                                  <p className="text-muted">
                                    Garantisci l'anonimato ai partecipanti che
                                    preferiscono mantenere privata la propria
                                    identità.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="features-box mt-4">
                              <div className="d-flex">
                                <div className="icon">
                                  <i className="mdi mdi-check-circle f-20 me-2" />
                                </div>
                                <div className="heading">
                                  <h6 className="mt-1">
                                    Connessione tra Utenti
                                  </h6>
                                  <p className="text-muted">
                                    Permetti ai partecipanti di connettersi e
                                    chattare con altri che con dividono opinioni
                                    simili.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="features-box mt-4">
                              <div className="d-flex">
                                <div className="icon">
                                  <i className="mdi mdi-check-circle f-20 me-2" />
                                </div>
                                <div className="heading">
                                  <h6 className="mt-1"> Sicurezza dei Dati</h6>
                                  <p className="text-muted">
                                    Proteggi i dati dei partecipanti impedendo
                                    modifiche alle infor mazioni personali per
                                    tre mesi.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="features-box mt-4">
                              <div className="d-flex">
                                <div className="icon">
                                  <i className="mdi mdi-check-circle f-20 me-2" />
                                </div>
                                <div className="heading">
                                  <h6 className="mt-1">
                                    {" "}
                                    Domande Preliminari di Accettazione
                                  </h6>
                                  <p className="text-muted">
                                    Seleziona i partecipanti con domande
                                    preliminari, sistemi di punt eggio e limiti
                                    di tempo per assicurare risposte di qualità.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="features-box mt-4">
                              <div className="d-flex">
                                <div className="icon">
                                  <i className="mdi mdi-check-circle f-20 me-2" />
                                </div>
                                <div className="heading">
                                  <h6 className="mt-1">
                                    {" "}
                                    Sponsorizzazioni e Premi
                                  </h6>
                                  <p className="text-muted">
                                    Finanzia i tuoi sondaggi con sponsor
                                    personali e motiva la parteci pazione con
                                    estrazioni a premi.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="features-box mt-4">
                              <div className="d-flex">
                                <div className="icon">
                                  <i className="mdi mdi-check-circle f-20 me-2" />
                                </div>
                                <div className="heading">
                                  <h6 className="mt-1"> Rappresentanza</h6>
                                  <p className="text-muted">
                                    Crea sondaggi come rappresentante verificato
                                    di gruppi o associazi oni per una maggiore
                                    credibilità.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="features-box mt-4">
                              <div className="d-flex">
                                <div className="icon">
                                  <i className="mdi mdi-check-circle f-20 me-2" />
                                </div>
                                <div className="heading">
                                  <h6 className="mt-1">
                                    {" "}
                                    Integrazione Blockchain
                                  </h6>
                                  <p className="text-muted">
                                    Stiamo esplorando la tecnologia blockchain
                                    per migliorare la sicurezza e la trasparenza
                                    nel voto.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end container */}
        </div>
        {/* end features */}
        {/* start testimonial */}
        {/* <section className="section bg-light testimonial" id="testimonial">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="title">
                  <p className=" text-primary fw-bold mb-0">
                    Client Testimonial{" "}
                    <i className="mdi mdi-cellphone-iphone" />
                  </p>
                  <h3>What they think of us!</h3>
                  <p className="text-muted">
                    Morbi at erat et tortor tempor sodales non eu lacus Donec at
                    gravida nunc vitae volutpat. Morbi in sem quis dui placerat
                    ornare.
                  </p>
                  <button className="btn bg-gradiant">
                    Read More Testimonial <i className="mdi mdi-arrow-right" />
                  </button>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="testi-slider" id="testi-slider">
                  <Slider {...settings}>
                    <div className="item carousel slide">
                      <div className="testi-box position-relative overflow-hidden">
                        <div className="row align-items-center">
                          <div className="col-md-5">
                            <div className="slide_img_wrapper">
                              <img
                                src="images/testi/img-1.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="p-4">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="avatar">
                                    <img
                                      src="images/user/img-1.jpg"
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="f-14 mb-0 text-dark fw-bold">
                                    <span className="text-muted fw-normal">
                                      Review By{" "}
                                    </span>{" "}
                                    Freanki Fabel
                                  </p>
                                  <div className="date">
                                    <p className="text-muted mb-0 f-14">
                                      28 jan, 2021 <span>10:25 AM</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 className="fw-bold">
                                  Bulid The App That Everyone Love.
                                </h5>
                                <p className="text-muted f-14">
                                  Start working with Styza that can provide
                                  everything you need to generate awareness,
                                  drive traffic, connect.
                                </p>
                                <button className="btn btn-sm bg-gradiant">
                                  <i className="mdi mdi-plus f-16 align-middle" />
                                  Follow
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="back-image position-absolute end-0 bottom-0">
                          <img
                            src="images/testi/rating-image.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="testi-box position-relative overflow-hidden">
                        <div className="row align-items-center">
                          <div className="col-md-5">
                            <div className="slide_img_wrapper">
                              <img
                                src="images/testi/img-2.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="p-4">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="avatar">
                                    <img
                                      src="images/user/img-2.jpg"
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="f-14 mb-0 text-dark fw-bold">
                                    <span className="text-muted fw-normal">
                                      Review By{" "}
                                    </span>{" "}
                                    Freanki Fabel
                                  </p>
                                  <div className="date">
                                    <p className="text-muted mb-0 f-14">
                                      28 jan, 2021 <span>10:25 AM</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 className="fw-bold">
                                  Easy and prefect solution
                                </h5>
                                <p className="text-muted f-14">
                                  Start working with Styza that can provide
                                  everything you need to generate awareness,
                                  drive traffic, connect.
                                </p>
                                <button className="btn btn-sm bg-gradiant">
                                  <i className="mdi mdi-plus f-16 align-middle" />
                                  Follow
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="back-image position-absolute end-0 bottom-0">
                          <img
                            src="images/testi/rating-image.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="item ">
                      <div className="testi-box position-relative overflow-hidden">
                        <div className="row align-items-center">
                          <div className="col-md-5">
                            <div className="slide_img_wrapper">
                              <img
                                src="images/testi/img-3.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="p-4">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="avatar">
                                    <img
                                      src="images/user/img-3.jpg"
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="f-14 mb-0 text-dark fw-bold">
                                    <span className="text-muted fw-normal">
                                      Review By{" "}
                                    </span>{" "}
                                    Freanki Fabel
                                  </p>
                                  <div className="date">
                                    <p className="text-muted mb-0 f-14">
                                      28 jan, 2021 <span>10:25 AM</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 className="fw-bold">
                                  Bulid The App That Everyone Love.
                                </h5>
                                <p className="text-muted f-14">
                                  Start working with Styza that can provide
                                  everything you need to generate awareness,
                                  drive traffic, connect.
                                </p>
                                <button className="btn btn-sm bg-gradiant">
                                  <i className="mdi mdi-plus f-16 align-middle" />
                                  Follow
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="back-image position-absolute end-0 bottom-0">
                          <img
                            src="images/testi/rating-image.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="item ">
                      <div className="testi-box position-relative overflow-hidden">
                        <div className="row align-items-center">
                          <div className="col-md-5">
                            <div className="slide_img_wrapper">
                              <img
                                src="images/testi/img-4.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="p-4">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="avatar">
                                    <img
                                      src="images/user/img-4.jpg"
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="f-14 mb-0 text-dark fw-bold">
                                    <span className="text-muted fw-normal">
                                      Review By{" "}
                                    </span>{" "}
                                    Freanki Fabel
                                  </p>
                                  <div className="date">
                                    <p className="text-muted mb-0 f-14">
                                      28 jan, 2021 <span>10:25 AM</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 className="fw-bold">
                                  Bulid The App That Everyone Love.
                                </h5>
                                <p className="text-muted f-14">
                                  Start working with Styza that can provide
                                  everything you need to generate awareness,
                                  drive traffic, connect.
                                </p>
                                <button className="btn btn-sm bg-gradiant">
                                  <i className="mdi mdi-plus f-16 align-middle" />
                                  Follow
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="back-image position-absolute end-0 bottom-0">
                          <img
                            src="images/testi/rating-image.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="section pricing" id="pricing">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="title text-center mb-5">
                  <h6 className="mb-0 fw-bold text-primary">
                    Qual è il Tuo Prezzo!
                  </h6>
                  <h2 className="f-40"> Piani Tariffari</h2>
                  <div className="mt-4">
                    Su Votiamo, offriamo piani tariffari trasparenti e
                    accessibili progettati per soddisfare le tue esigenze. Il
                    costo è a carico dell’organizzatore e parte da soli €0,04
                    per partecipante. Ciò significa che puoi coin volgere
                    efficacemente il tuo pubblico senza spendere una fortuna.
                    Scegli il piano che si adatta alle dimen sioni del tuo
                    sondaggio e goditi tutte le nostre funzi onalità premium
                    senza costi nascosti o sorprese.
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="price-item shadow-sm overflow-hidden">
                  <div className="price-up-box active p-4">
                    <div className="badge bg-primary fw-normal f-14">Basic</div>
                    <div className="price-tag mt-2">
                      <h2 className="text-white mb-0 f-40">
                        <sup className="f-22 fw-normal">$</sup>09
                        <sup className="f-16 fw-normal"> /month</sup>
                      </h2>
                    </div>
                    <p className="text-white-50 mb-1">17 to 19 user</p>
                    <p className="text-white-50 mb-0">
                      For most businesses that want to optimize web query.
                    </p>
                  </div>
                  <div className="border border-3" />
                  <div className="price-down-box p-4">
                    <ul className="list-unstyled ">
                      <li>
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        2 App and project
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        400 Gb/s storange
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Free coustom domain
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-close-circle-outline f-20 align-middle me-2 text-danger" />
                        Chat Support
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-close-circle-outline f-20 align-middle me-2 text-danger" />
                        No transaction fees
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-close-circle-outline f-20 align-middle me-2 text-danger" />
                        Unlimited Storage
                      </li>
                    </ul>
                    <a href className="btn btn-sm text-primary mt-3">
                      <i className="mdi mdi-check-all me-2" />
                      your plane
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 ">
                <div className="price-item shadow-sm overflow-hidden mt-4 mt-lg-0">
                  <div className="topbar-header bg-primary py-2 text-center">
                    <h6 className="mb-0 text-white fw-normal">
                      Recommended For You
                    </h6>
                  </div>
                  <div className="price-up-box p-4">
                    <div className="badge bg-primary fw-normal f-14">
                      Startup
                    </div>
                    <div className="price-tag mt-2">
                      <h2 className="text-dark mb-0 f-40">
                        <sup className="f-22 fw-normal">$</sup>19
                        <sup className="f-16 fw-normal"> /month</sup>
                      </h2>
                    </div>
                    <p className="text-muted mb-1">20 to 40 user</p>
                    <p className="text-muted mb-0">
                      For most businesses that want to optimize web query.
                    </p>
                  </div>
                  <div className="border border-3" />
                  <div className="price-down-box p-4">
                    <ul className="list-unstyled ">
                      <li>
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        15 App and project
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        800 Gb/s storange
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Free coustom domain
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Chat Support
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-close-circle-outline f-20 align-middle me-2 text-danger" />
                        No transaction fees
                      </li>
                      <li className="mt-2">
                        <i className="mdi  mdi-close-circle-outline f-20 align-middle me-2 text-danger" />
                        Unlimited Storage
                      </li>
                    </ul>
                    <a href className="btn btn-sm btn-primary mt-3">
                      <i className="mdi mdi-check-all me-2" />
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="price-item shadow-sm overflow-hidden mt-4 mt-lg-0">
                  <div className="price-up-box p-4">
                    <div className="badge bg-primary fw-normal f-14">
                      Enterprise
                    </div>
                    <div className="price-tag mt-2">
                      <h2 className="text-dark mb-0 f-40">
                        <sup className="f-22 fw-normal">$</sup>29
                        <sup className="f-16 fw-normal"> /month</sup>
                      </h2>
                    </div>
                    <p className="text-muted mb-1">17 to 19 user</p>
                    <p className="text-muted mb-0">
                      For most businesses that want to optimize web query.
                    </p>
                  </div>
                  <div className="border border-3" />
                  <div className="price-down-box p-4">
                    <ul className="list-unstyled ">
                      <li>
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Unlimited App and project
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        1000 Gb/s storange
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Free coustom domain
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Chat Support
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        No transaction fees
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Unlimited Storage
                      </li>
                    </ul>
                    <a href className="btn btn-sm btn-primary mt-3">
                      <i className="mdi mdi-check-all me-2" />
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>

        {/* end pricing */}
        {/* slider section */}
        <section className="section app-slider bg-light" id="screen_shot">
          {/* start container */}
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="title text-center mb-5">
                  <h6 className="mb-0 fw-bold text-primary">App Screen!</h6>
                  <h2 className="f-40">Mostra gli screenshot della nostra app!</h2>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolor eos <br />
                    inventore omnis aliquid rerum alias molestias.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="swiper-container">
                  <div className="fream-phone ">
                    <img
                      src="/images/testi/phone-fream.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <Slider
                    {...options}
                    draggable={true}
                    swipeToSlide={1}
                    pauseOnHover={false}
                    pauseOnDotsHover={false}
                  >
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/05.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/06.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/07.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/08.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/09.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/10.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/11.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/12.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/13.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/14.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/15.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/16.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </Slider>
                  {/* navigation buttons */}
                  <div className="swiper-pagination" />
                </div>
              </div>
            </div>
            {/* end container */}
          </div>
        </section>
        {/* end section */}
        {/* cta section */}
        <section className="section cta" id="download">
          <div className="bg-overlay-gradiant" />
          {/* start container */}
          <div className="container position-relative">
            <div className="row">
              <div className="col-lg-6">
                <div className="py-5">
                  <h1 className="display-4 text-white">Scarica Votiamo Oggi</h1>
                  <p className="text-white-50 mt-3 f-18">
                    Fai il primo passo verso una nuova esperienza di sondaggi.
                    Scarica ora l’app Votiamo e inizia a coinvolgere il tuo
                    pubblico in modo innovativo ed entusiasmante!
                  </p>
                  <div className="d-flex mt-4 ">
                    <div className="app-store">
                      <a href="https://apps.apple.com/in/app/votiamo/id6449703970" target="_blank">
                        <img
                          src="images/appstore.svg"
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div className="googleplay">
                      <a href="https://play.google.com/store/apps/details?id=com.app.votiamo" target="_blank">
                        <img
                          src="images/playstore.svg"
                          alt=""
                          className="img-fluid ms-3"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cta-phone-image">
                  <img src="images/17.png" alt="" className=" img-fluid" />
                </div>
              </div>
            </div>
          </div>
          {/* end container */}
        </section>
        {/* end section */}
        {/* contact section */}
        {/* <section className="section contact overflow-hidden" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="title text-center mb-5">
                  <h6 className="mb-0 fw-bold text-primary">Contact Us</h6>
                  <h2 className="f-40">Get In Touch!</h2>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolor eos <br />
                    inventore omnis aliquid rerum alias molestias.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="contact-box">
                  <div className="mb-4">
                    <h4 className=" fw-semibold mb-1">Need Support !</h4>
                    <p className="text-muted">
                      Contact us for a quote , help to join the them.
                    </p>
                  </div>
                  <div className="custom-form mt-4 ">
                    <form
                      method="post"
                      name="myForm"
                      onSubmit="return validateForm()"
                    >
                      <p id="error-msg" style={{ opacity: 1 }}>
                      </p>
                      <div id="simple-msg" />
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control contact-form"
                              placeholder="Your name"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              name="email"
                              id="email"
                              type="email"
                              className="form-control contact-form"
                              placeholder="Your email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mt-2">
                            <input
                              type="text"
                              className="form-control contact-form"
                              id="subject"
                              placeholder="Your Subject.."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mt-2">
                            <textarea
                              name="comments"
                              id="comments"
                              rows={4}
                              className="form-control contact-form h-auto"
                              placeholder="Your message..."
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-lg-12 d-grid">
                          <input
                            type="submit"
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-rounded btn-primary"
                            defaultValue="Send Message"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="m-5">
                  <div className="position-relative">
                    <div className="contact-map">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d29754.94142818836!2d72.88699279999999!3d21.217263799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1641286801478!5m2!1sen!2sin"
                        width={550}
                        height={450}
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                      />
                    </div>
                    <div className="map-shape" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-google-maps f-50 text-primary" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-1">Location</h5>
                    <p className="f-14 mb-0 text-muted">
                      2276 Lynn Ogden Lane Beaumont
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center mt-4 mt-lg-0">
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-email f-50 text-primary" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-1">Email</h5>
                    <p className="f-14 mb-0 text-muted">
                      Email: FredVWeaver@rhyta.com
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center mt-4 mt-lg-0">
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-phone f-50 text-primary" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-1">Phone</h5>
                    <p className="f-14 mb-0 text-muted">
                      2276 Lynn Ogden Lane Beaumont
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* end section */}
        {/* footer section */}
        <section className=" section footer bg-dark overflow-hidden" id="contact">
          <div className="bg-arrow"></div>
          {/* container */}
          <div className="container">
            <div className="row ">
              <div className="col-lg-4">
                <a
                  className="navbar-brand logo text-uppercase"
                  href="index-1.html"
                >
                  <img
                    src="images/logo-footer.png"
                    className="logo-light"
                    alt=""
                    height={30}
                  />
                </a>
                <p className="text-white-50 mt-2 mb-0">
                  Un modo più efficace e coinvolgente per interagire con il tuo pubblico. Che tu stia conducendo
                  ricerche di mercato, organizzando un evento o semplicemente raccogliendo opinioni, Votiamo
                  offre gli strumenti per farlo in modo semplice, sicuro e personalizzato.
                </p>
                {/* <div className="footer-icon mt-4">
                  <div className=" d-flex align-items-center">
                    <a
                      href
                      className="me-2 avatar-sm text-center"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Facebook"
                    >
                      <i className="mdi mdi-facebook f-24 align-middle text-primary" />
                    </a>
                    <a
                      href
                      className="mx-2 avatar-sm text-center"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Twitter"
                    >
                      <i className="mdi mdi-twitter f-24 align-middle text-primary" />
                    </a>
                    <a
                      href
                      className="mx-2 avatar-sm text-center"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Googleplay"
                    >
                      <i className="mdi mdi-google-play f-24 align-middle text-primary" />
                    </a>
                    <a
                      href
                      className="mx-2 avatar-sm text-center"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Linkedin"
                    >
                      <i className="mdi mdi-linkedin f-24 align-middle text-primary" />
                    </a>
                  </div>
                </div> */}
              </div>
              <div className="col-md-2 ">
                <div className="text-start mt-4 mt-lg-0">
                  <h5 className="text-white fw-bold">Prodotto</h5>
                  <ul className="footer-item list-unstyled footer-link mt-3">
                    <li>
                      <a href="/#features">funzionalità</a>
                    </li>
                    <li>
                      <a href="/#pricing">Prezzi</a>
                    </li>
                    <li>
                      <a href="/#download">app</a>
                    </li>
                    {/* <li>
                      <a href>Contact</a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-md-2 ">
                <div className="text-start">
                  <h5 className="text-white fw-bold">Politiche</h5>
                  <ul className="footer-item list-unstyled footer-link mt-3">
                    <li>
                      <a href="/privacy/it" target="_blank">Privacy</a>
                    </li>
                    {/* <li>
                      <a href>Marketplace</a>
                    </li> */}
                    <li>
                      <a href="/terms/it" target="_blank">Termini e Condizioni</a>
                    </li>
                    <li>
                      <a href="/support/it" target="_blank">Supporto</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                {/* <h5 className="text-white">Subscribe</h5>
                <div className="input-group my-4">
                  <input
                    type="text"
                    className="form-control p-3"
                    placeholder="subscribe"
                    aria-label="subscribe"
                    aria-describedby="basic-addon2"
                  />
                  <a
                    href
                    className="input-group-text bg-primary text-white px-4"
                    id="basic-addon2"
                  >
                    Go
                  </a>
                </div>
                <p className="mb-0 text-white-50">
                  publishes will show up in your Subscriptions feed. You may
                  also start.
                </p> */}
                <h5 className="text-white fw-bold">Contatti</h5>
                <p className="text-white-50 mt-2 mb-0">
                  Hai domande o bisogno di assistenza? Il nostro team è qui per aiutarti. Contattaci a <a href="mailto:support@votiamo.com">support@votiamo.com</a> e ti risponderemo al più presto
                </p>
              </div>
            </div>
          </div>
          {/* end container */}
        </section>
        <section className="bottom-footer py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <p className="mb-0 text-center text-muted">
                  © 2024 Votiamo. Tutti i diritti riservati.{" "}
                  <i className="mdi mdi-heart text-danger" /> by{" "}
                  <a href="#" target="_blank" className="text-muted">
                    Votiamo.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* end footer */}
        {/* Style switcher */}
        {/* end switcher*/}
        {/*Bootstrap Js*/}
        {/* Slider Js */}
        {/*  */}
        {/* counter */}
        {/*  */}
        {/* App Js */}
      </div>
    </React.Fragment>
  );
};

export default Home;
