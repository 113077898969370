export const termsHtml = (logo) => {
    return (`
    
<!DOCTYPE html>
<html lang="it">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Votiamo - Termini e Condizioni</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            margin: 0;
            padding: 0 20px;
            background-color: #f9f9f9;
        }
        header {
            text-align: center;
            background-color: #fff;
            color: #124559;
            padding: 20px 0;
        }
        h1 {
            margin: 0;
        }
        section {
            margin: 20px 0;
            padding: 20px;
            background: white;
            border: 1px solid #ddd;
            border-radius: 5px;
        }
        h2 {
            color: #124559;
            margin-top: 0;
        }
        ul {
            padding-left: 20px;
        }
        li {
            margin-bottom: 10px;
        }
        footer {
            text-align: center;
            margin: 20px 0;
            color: #777;
        }
        a {
            color: #124559;
        }
    </style>
</head>
<body>
<header>
    <img src="${logo}" alt="Logo Votiamo" style="max-width: 150px; margin: 20px auto; display: block;">
    <h1>Termini e Condizioni</h1>
</header>

<section>
    <h2>Benvenuto/a</h2>
    <p>Benvenuto/a su Votiamo. L'utilizzo dell'app implica l'accettazione dei seguenti Termini e Condizioni. Si prega di leggere attentamente quanto segue prima di utilizzare l'app. In caso di domande, contatta il nostro team di supporto.</p>
</section>

<section>
    <h2>1. Scopo dell'App</h2>
    <p>Votiamo è un’applicazione progettata per creare e partecipare a sondaggi in modo interattivo. L’app fornisce strumenti per raccogliere e analizzare opinioni, garantendo al contempo la privacy degli utenti.</p>
</section>

<section>
    <h2>2. Registrazione e Dati dell'Utente</h2>
    <p>Per registrarsi e utilizzare Votiamo, gli utenti devono fornire alcune informazioni di base, tra cui:</p>
    <ul>
        <li>Data di nascita</li>
        <li>Genere</li>
        <li>Città di residenza</li>
        <li>Stato di famiglia</li>
        <li>Titolo di studio</li>
    </ul>
    <p>Questi dati vengono utilizzati esclusivamente a fini statistici e di analisi dei sondaggi. Non è richiesto alcun documento di verifica per tali informazioni, e i contatti personali (come email e numero di telefono) non saranno mai resi visibili né comunicati ai creatori dei sondaggi o ad altri utenti.</p>
</section>

<section>
    <h2>3. Privacy e Sicurezza</h2>
    <p>Votiamo si impegna a proteggere la privacy degli utenti. Gli utenti possono decidere se rendere visibili le informazioni del proprio profilo ad altri utenti o nasconderle. È anche possibile partecipare in modo anonimo, se il creatore del sondaggio abilita questa opzione. Le risposte fornite nei sondaggi anonimi non saranno associate al profilo dell'utente né visibili al creatore del sondaggio. Le informazioni di contatto non saranno mai divulgate ad altri utenti.</p>
</section>

<section>
    <h2>4. Funzionalità dell'App</h2>
    <ul>
        <li>Creazione di Sondaggi: I creatori possono impostare sondaggi pubblici o privati e limitare la partecipazione in base a determinate caratteristiche (es. età, città).</li>
        <li>Partecipazione ai Sondaggi: Gli utenti possono partecipare ai sondaggi in modalità privata o anonima.</li>
        <li>Statistiche Avanzate: I risultati dei sondaggi possono essere analizzati attraverso filtri specifici per ottenere analisi dettagliate.</li>
    </ul>
</section>

<section>
    <h2>5. Responsabilità dell’Utente</h2>
    <ul>
        <li><strong>Accuratezza dei Dati:</strong> Gli utenti sono responsabili della veridicità delle informazioni fornite.</li>
        <li><strong>Comportamento Adeguato:</strong> È vietato utilizzare l'app per scopi illegali o fraudolenti.</li>
        <li><strong>Contenuti dei Sondaggi:</strong> I creatori devono evitare contenuti illeciti, osceni o fraudolenti.</li>
        <li><strong>Segnalazione di Abusi:</strong> Segnalare contenuti inappropriati a <a href="mailto:support@votiamo.com">support@votiamo.com</a>.</li>
    </ul>
</section>

<section>
    <h2>6. Limitazioni di Utilizzo</h2>
    <p>Per mantenere un ambiente sicuro, sono vietati:</p>
    <ul>
        <li>Contenuti illeciti o osceni</li>
        <li>Attività fraudolente</li>
        <li>Abusi o molestie verso altri utenti</li>
    </ul>
</section>

<section>
    <h2>7. Proprietà Intellettuale</h2>
    <p>Tutti i contenuti, i marchi, il logo e le funzionalità dell’app sono di proprietà di Votiamo o dei suoi licenziatari. Gli utenti non possono copiare, modificare, o distribuire i contenuti dell’app senza previa autorizzazione scritta.</p>
</section>

<section>
    <h2>8. Limitazione di Responsabilità</h2>
    <p>Votiamo non è responsabile per eventuali danni derivanti dall’uso dell’app. L’app fornisce una piattaforma per la creazione di sondaggi, ma non è responsabile dell’accuratezza dei risultati né delle decisioni basate su di essi.</p>
</section>

<section>
    <h2>9. Modifiche ai Termini</h2>
    <p>Votiamo si riserva il diritto di modificare questi Termini e Condizioni. Le modifiche entreranno in vigore al momento della pubblicazione.</p>
</section>

<section>
    <h2>10. Recesso e Cancellazione dell’Account</h2>
    <p>Gli utenti possono cancellare il proprio account in qualsiasi momento. Potrebbero essere necessari dai 3 ai 6 mesi per registrarsi nuovamente utilizzando gli stessi contatti.</p>
</section>

<section>
    <h2>11. Contatti</h2>
    <p>Per domande o assistenza, contatta il nostro team tramite l’app o scrivi a <a href="mailto:support@votiamo.com">support@votiamo.com</a>.</p>
</section>

<footer>
    <p>&copy; 2024 Votiamo. Tutti i diritti riservati.</p>
</footer>
</body>
</html>

`)
};