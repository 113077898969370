import React from "react";
import { contentRulesHtml } from '../static/ContentRules';

const ContestRules = () => {
  return (
    <div style={{ width: "100%", overflowX: "hidden" }} dangerouslySetInnerHTML={{ __html: contentRulesHtml() }}></div>
  );
};

export default ContestRules;
