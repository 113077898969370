import axios from "axios";

export const getSurveyResultsArray = async (surveyId, ids, gender, minage, maxage, country, region, city, education, civil, italy_region, proviancia, comune, characAnswers, userId, setResultLoading, setIsShowMatchAnsBtn) => {
    setResultLoading(true);
    let arr = [];
    let mArr = [];
    const requests = ids.map(async elem => {
        const url = `${process.env.REACT_APP_BASE_URL}/survey/result/${surveyId}/${elem}/v2?gender=${gender}&minage=${minage}&maxage=${maxage}&country=${country}&region=${region}&city=${city}&education=${education}&civil=${civil}&italy_region=${italy_region}&proviancia=${proviancia}&comune=${comune}&userId=${userId}`;
        const response = await axios.put(url, characAnswers);
        if (response.data.isShowMatchButton) {
            setIsShowMatchAnsBtn(true);
        }
        if (userId) {
            mArr.push({ [elem]: response.data.sameAnswers });
        }
        arr.push({ [elem]: response.data.data });
    });
    await Promise.all(requests);
    setResultLoading(false);
    return {
        arr,
        mArr
    };
};