export const termsHtml = (logo) => {
    return (`
    
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Votiamo - Terms and Conditions</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            margin: 0;
            padding: 0 20px;
            background-color: #f9f9f9;
        }
        header {
            text-align: center;
            background-color: #fff;
            color: #124559;
            padding: 20px 0;
        }
        h1 {
            margin: 0;
        }
        section {
            margin: 20px 0;
            padding: 20px;
            background: white;
            border: 1px solid #ddd;
            border-radius: 5px;
        }
        h2 {
            color: #124559;
            margin-top: 0;
        }
        ul {
            padding-left: 20px;
        }
        li {
            margin-bottom: 10px;
        }
        footer {
            text-align: center;
            margin: 20px 0;
            color: #777;
        }
        a {
            color: #124559;
        }
    </style>
</head>
<body>
<header>
    <img src="${logo}" alt="Votiamo Logo" style="max-width: 150px; margin: 20px auto; display: block;">
    <h1>Terms and Conditions</h1>
</header>

    <section>
        <h2>Welcome</h2>
        <p>Welcome to Votiamo. Using the app implies acceptance of the following Terms and Conditions. Please read the following carefully before using the app. If you have any questions, contact our support team.</p>
    </section>

    <section>
        <h2>1. Purpose of the App</h2>
        <p>Votiamo is an application designed to create and participate in surveys interactively. The app provides tools to collect and analyze opinions while ensuring user privacy.</p>
    </section>

    <section>
        <h2>2. Registration and User Data</h2>
        <p>To register and use Votiamo, users must provide some basic information, including:</p>
        <ul>
            <li>Date of birth</li>
            <li>Gender</li>
            <li>City of residence</li>
            <li>Family status</li>
            <li>Education level</li>
        </ul>
        <p>These data are used exclusively for statistical purposes and survey analysis. No verification documents are required for this information, and personal contacts (such as email and phone number) will never be made visible or communicated to survey creators or other users.</p>
    </section>

    <section>
        <h2>3. Privacy and Security</h2>
        <p>Votiamo is committed to protecting user privacy. Users can decide whether to make their profile information visible to other users or hide it. It is also possible to participate anonymously if the survey creator enables this option. Responses provided in anonymous surveys will not be associated with the user's profile or visible to the survey creator. Contact information will never be disclosed to other users.</p>
    </section>

    <section>
        <h2>4. App Features</h2>
        <ul>
            <li>Survey Creation: Creators can set up public or private surveys and limit participation based on certain characteristics (e.g., age, city).</li>
            <li>Survey Participation: Users can participate in surveys in private or anonymous mode.</li>
            <li>Advanced Statistics: Survey results can be analyzed through specific filters to obtain detailed analyses.</li>
        </ul>
    </section>

    <section>
        <h2>5. User Responsibilities</h2>
        <ul>
            <li><strong>Data Accuracy:</strong> Users are responsible for the accuracy of their data.</li>
            <li><strong>Appropriate Conduct:</strong> Use the app respectfully. Prohibited activities include fraudulent or illegal use.</li>
            <li><strong>Survey Content:</strong> Survey creators must avoid illegal, obscene, or fraudulent content.</li>
            <li><strong>Reporting Abuse:</strong> Report inappropriate content to <a href="mailto:support@votiamo.com">support@votiamo.com</a>.</li>
        </ul>
    </section>

    <section>
        <h2>6. Usage Limitations</h2>
        <p>To maintain a safe environment, the following are prohibited:</p>
        <ul>
            <li>Illegal or obscene survey content</li>
            <li>Fraudulent activities</li>
            <li>Abuse or harassment of users</li>
        </ul>
    </section>

    <section>
        <h2>7. Intellectual Property</h2>
        <p>All content, trademarks, logos, and features of the app are the property of Votiamo or its licensors. Users may not copy, modify, or distribute the app's content without prior written authorization.</p>
    </section>

    <section>
        <h2>8. Limitation of Liability</h2>
        <p>Votiamo is not responsible for any damages arising from the use of the app. The app provides a platform for surveys but is not responsible for the accuracy of results or decisions made based on them.</p>
    </section>

    <section>
        <h2>9. Changes to the Terms</h2>
        <p>Votiamo reserves the right to modify these Terms and Conditions. Changes will become effective upon publication.</p>
    </section>

    <section>
        <h2>10. Withdrawal and Account Deletion</h2>
        <p>Users can delete their account at any time. However, a waiting period of 3-6 months may apply before re-registration with the same contact information.</p>
    </section>

    <section>
        <h2>11. Contacts</h2>
        <p>For questions or assistance, contact the support team through the app or via email at <a href="mailto:support@votiamo.com">support@votiamo.com</a>.</p>
    </section>

    <footer>
        <p>&copy; 2024 Votiamo. All rights reserved.</p>
    </footer>
</body>
</html>

`)
};