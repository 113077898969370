import React, { useEffect, useState } from "react";
import Loader from "../loader/Loader";
import Navigation from "../components/Navigation";
import { useDispatch, useSelector } from "react-redux";
import "./Survey.css";
import { Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as surveyActions from "../redux/survey/survey.actions";
import moment from "moment";
import { toast } from "react-toastify";

const SurveyDetails = () => {

  const [data, setData] = useState({});
  const id = useParams().id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.surveyInfo.loading);
  const details = useSelector((state) => state.surveyInfo.details);

  useEffect(() => {
    if (id) {
      dispatch(surveyActions.getPublicSurveyDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && Object.keys(details).length > 0) {
      setData(details);
    } else {
      setData({});
    }
  }, [details]);

  const handleButtonClick = (url) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url;
    }
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <Navigation />
      {loading ? (
        <Loader />
      ) : (
        <>
          {
            data && Object.keys(data).length > 0 ?
              <>
                <section className="survey">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <span className="page_breadcrumb">
                          <img onClick={e => navigate("/survey")} src={`${process.env.PUBLIC_URL}/images/survey/backIcon.png`} />
                          <ul>
                            <li onClick={e => navigate("/survey")}>Tutto</li>
                            <li>{data?.title}</li>
                          </ul>
                        </span>
                      </div>
                      <div className="col-12">
                        <div className="row mb-5">
                          <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12 mt-4">
                            <div className="survey_thumbnail_card">
                              <img src={data?.banner ? `${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${data.banner}` : `${process.env.PUBLIC_URL}/images/votiamo_mini.png`} />
                              <div className="survey_thumbnail_content">
                                <div className="survey_thumbnail_left_content">
                                  <img src={`${process.env.PUBLIC_URL}/images/survey/giftIcon.png`} />
                                  <p><span>{data?.questionsLength}</span> Domande{(data?.questionsLength > 1) && `'s`}</p>
                                </div>
                                <div className="survey_thumbnail_right_content">
                                  <img src={data?.createdByUser?.image ? `${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${data.createdByUser.image}` : `${process.env.PUBLIC_URL}/images/no_profile.png`} />
                                  <p>Creato da <span>{data?.createdByUser?.name}</span></p>
                                </div>
                              </div>
                            </div>
                            {
                              data?.prize &&
                              <>
                                <div className="survey_prize_card mt-4">
                                  <img src={`${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${data?.prize?.image}`} />
                                  <div className="survey_prize_content">
                                    <p>Premio in palio</p>
                                    <h6>Premio totale : {data?.prize?.numberOfPrizes}</h6>
                                    <span>{data?.prize?.description}</span>
                                  </div>
                                </div>
                                {
                                  data.prize?.winnerUsers && data.prize.winnerUsers.length > 0 &&
                                  <>
                                    {
                                      data.prize.winnerUsers.map(elem => (
                                        <div className="survey_winner mt-4" key={elem._id}>
                                          <span className="survey_winner_heading"><img src={`${process.env.PUBLIC_URL}/images/survey/prizeIcon.png`} /> Vincitore del sondaggio</span>
                                          <div className="survey_winner_content mt-3">
                                            <img src={elem?.image ? `${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${elem.image}` : `${process.env.PUBLIC_URL}/images/no_profile.png`} />
                                            <div>
                                              <span className="survey_winner_name">{elem.name}</span>
                                              {
                                                elem?.nation_country &&
                                                <span className="survey_winner_place">{elem.nation_country === "Italy" ? `${elem.region},` : `${elem.region_state},`} {elem.nation_country}</span>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    }
                                  </>
                                }
                              </>
                            }
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-7 col-sm-12 mt-4">
                            <div className="survey_content">
                              <div className="survey_content_tags d-flex gap-3">
                                {data?.active ? (
                                  <span className="survey_card_status survey_card_active position-relative top-0 start-0">
                                    Attivo
                                  </span>
                                ) : (
                                  <span className="survey_card_status survey_card_completed position-relative top-0 start-0">
                                    Tentato
                                  </span>
                                )}
                                <span className="survey_card_status survey_card_completed position-relative top-0 start-0">
                                  Pubblico
                                </span>
                              </div>
                              <div className="my-4">
                                <h3 className="survey_title mt-3">{data?.title}</h3>
                                <p className="survey_description mt-3">{data?.description}</p>
                              </div>
                              <div className="survey_link_section my-3 d-flex align-items-center gap-2">
                                <strong>Link del sondaggio per l'app:</strong>
                                <a
                                  href={`https://votiamo.com/surveydetails/?id=${id}`} // Replace with the actual survey link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="survey_link"
                                >
                                  Apri sondaggio
                                </a>
                                <button
                                  style={{ border: "none", outline: "none", background: "#fff" }}
                                  className="copy_to_clipboard"
                                  onClick={() => {
                                    navigator.clipboard.writeText(`https://votiamo.com/surveydetails/?id=${id}`);
                                    toast("Link copiato.");
                                  }}
                                  title="Copy to Clipboard"
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/survey/copy.png`}
                                    style={{ width: "20px" }}
                                    alt="Copy"
                                  />
                                </button>
                              </div>
                              <div className="survey_extra_info d-flex justify-content-between">
                                <div className="survey_extra mt-3">
                                  <div>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/survey/calendarBlueIcon.png`}
                                    />
                                    Fine <span>{moment(new Date(data?.setting?.endDate)).format("DD MMM, YY")}</span>
                                  </div>
                                  <div>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/survey/peopleBlueIcon.png`}
                                    />
                                    <span>{data?.attemptedUsersCount} </span>Tentativo
                                  </div>
                                </div>
                                {/* <div
                                  className={`survey_show_result_btn mt-3 ${data?.setting?.liveResult
                                    ? ""
                                    : data?.active
                                      ? "disabled"
                                      : ""
                                    }`}
                                  onClick={(e) => navigate(`/survey-results/${data._id}`)}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/images/survey/calendarIcon.png`}
                                  />
                                  Show Survey Result
                                </div> */}
                                {(data?.setting?.liveResult || !data?.active) && (
                                  <div
                                    className="survey_show_result_btn mt-3"
                                    onClick={(e) => navigate(`/survey-results/${data._id}`)}
                                  >
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/survey/calendarIcon.png`}
                                      alt="Calendar Icon"
                                    />
                                    Mostra i risultati del sondaggio
                                  </div>
                                )}
                              </div>
                            </div>
                            <hr className="row_divider" />
                            <div className="survey_createdby_content">
                              {
                                data?.group ?
                                  <>
                                    <div className="row">
                                      <div className="col-12">
                                        <h6 className="survey_section_heading">Creato da</h6>
                                      </div>
                                      <div className="col-lg-6 mt-3">
                                        <div className="createdby_left_box">
                                          <span>Nome del gruppo</span>
                                          <h5>{data?.group?.name}</h5>
                                          <p>{data?.group?.description}</p>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mt-3">
                                        <ul className="createdby_right_box">
                                          <li>Nome del referente<span>{data?.group?.refName}</span></li>
                                          <li>Cognome del referente<span>{data?.group?.refSurname}</span></li>
                                          <li>Email<span>{data?.group?.email}</span></li>
                                          <li>Numero di telefono<span>{data?.group?.phone}</span></li>
                                          <li>Paese<span>{data?.group?.country_name}</span></li>
                                          {
                                            data?.group?.country_name === "Italy" ?
                                              <>
                                                <li>Regione<span>{data?.group?.region_name}</span></li>
                                                <li>Provincia<span>{data?.group?.provincia_name}</span></li>
                                                <li>Comune<span>{data?.group?.comune_name}</span></li>
                                              </> :
                                              <>
                                                <li>Stato<span>{data?.group?.state_name}</span></li>
                                                <li>Città<span>{data?.group?.city_name}</span></li>
                                              </>
                                          }
                                          <li>Primo indirizzo<span>{data?.group?.addressLine1}</span></li>
                                          <li>Secondo indirizzo<span>{data?.group?.addressLine2}</span></li>
                                        </ul>
                                      </div>
                                    </div>
                                  </>
                                  :
                                  <>
                                    <div className="row">
                                      <div className="col-12">
                                        <h6 className="survey_section_heading">Creato da</h6>
                                      </div>
                                      <div className="col-lg-6 mt-3">
                                        <div className="createdby_left_box">
                                          <img style={{ width: "50px", height: "50px" }} className="rounded-circle" src={data?.createdByUser?.image ? `${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${data.createdByUser.image}` : `${process.env.PUBLIC_URL}/images/no_profile.png`} />
                                          <h5>{data?.createdByUser?.name}</h5>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 mt-3">
                                        <ul className="createdby_right_box">
                                          {data?.createdByUser?.nation_country && <li>Paese<span>{data?.createdByUser?.nation_country}</span></li>}
                                          {
                                            data?.createdByUser?.nation_country === "Italy" ?
                                              <>
                                                {data?.createdByUser?.region && <li>Regione<span>{data?.createdByUser?.region}</span></li>}
                                                {data?.createdByUser?.provincia && <li>Province<span>{data?.createdByUser?.provincia}</span></li>}
                                                {data?.createdByUser?.comune && <li>Comune<span>{data?.createdByUser?.comune}</span></li>}
                                              </> :
                                              <>
                                                {data?.createdByUser?.region_state && <li>Stato<span>{data?.createdByUser?.region_state}</span></li>}
                                                {data?.createdByUser?.city && <li>Città<span>{data?.createdByUser?.city}</span></li>}
                                              </>
                                          }
                                        </ul>
                                      </div>
                                    </div>
                                  </>
                              }
                            </div>
                            <hr className="row_divider" />
                            <div className="survey_sponsor_content">
                              <div className="row">
                                {
                                  data?.sponsors?.map(elem => (
                                    <div className="col-12">
                                      <h6 className="survey_section_heading"><img src={`${process.env.PUBLIC_URL}/images/survey/moneyIcon.png`} /> Sponsorizzato da</h6>
                                    </div>
                                  ))
                                }
                                <div className="col-12">
                                  <div className="row">
                                    {
                                      data?.sponsors?.map(elem => (
                                        <Col xl={3} lg={4} md={6} sm={4} xs={6} className="mt-3" key={elem._id}>
                                          <div className="sponsor_card">
                                            <img src={`${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${elem?.image}`} />
                                            <h6>{elem?.name}</h6>
                                            <p style={{ userSelect: "none", cursor: "pointer" }} onClick={e => handleButtonClick(elem?.link)}>{elem?.link}</p>
                                          </div>
                                        </Col>
                                      ))
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="row_divider" />
                            <div className="survey_attempt_content">
                              <div className="row">
                                <div className="col-12">
                                  <h6 className="survey_section_heading">Elenco dei tentativi({data?.attemptedUsersCount})</h6>
                                </div>
                              </div>
                            </div>
                            {/* {
                              data?.attendedUsers && data?.attendedUsers.length > 0 &&
                              <div className="survey_attempt_content">
                                <div className="row">
                                  <div className="col-12">
                                    <h6 className="survey_section_heading">Attempts List({data?.attemptedUsersCount})</h6>
                                  </div>
                                  <div className="col-12">
                                    <div className="row">
                                      {
                                        data.attendedUsers.map((elem, i) => (
                                          <Col lg={2} md={3} sm={2} xs={3} key={i}>
                                            <div className="participant_card">
                                              <img src={elem.image ? `${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${elem.image}` : `${process.env.PUBLIC_URL}/images/no_profile.png`} />
                                              <span>{elem?.name}</span>
                                              <p>{moment(new Date(elem?.createdAt)).format("DD MMM, YY")}</p>
                                            </div>
                                          </Col>
                                        ))
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            } */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </> :
              <h2 className="text-muted text-center mt-3">No Data Found</h2>
          }
        </>
      )}
    </>
  );
};

export default SurveyDetails;
