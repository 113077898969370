import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import * as surveyActions from "../redux/survey/survey.actions";

const MatchUser = ({ matchUserModal, matchUserToggle, matchAnswers, userId, matchId }) => {

  const [matchData, setMatchData] = useState([]);
  const [selectedMatchData, setSelectedMatchData] = useState([]);

  const [open, setOpen] = useState('1');
  const dispatch = useDispatch();

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  useEffect(() => {
    if (matchId) {
      let finding = matchAnswers.find(item => item[matchId])[matchId] || [];
      setSelectedMatchData(finding);
    }
  }, [matchId, matchAnswers]);

  useEffect(() => {
    if (selectedMatchData && selectedMatchData.length > 0) {
      setMatchData(selectedMatchData);
    } else {
      setMatchData([]);
    }
  }, [selectedMatchData]);

  const handleAddFriendBtn = (id) => {
    if (!userId) {
      toast("Qualcosa è andato storto.");
      return;
    }
    dispatch(surveyActions.sendFriendRequest(id, userId, handleSetDataAfterApiCall));
  };

  const handleSetDataAfterApiCall = (id) => {
    const newData = selectedMatchData.map((d, i) => {
      if (d.createdBy === id) {
        selectedMatchData[i].isFriend = "Pending";
      }
      return d;
    });
    setMatchData(newData);
  };

  const _calculateAge = (birthday) => {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  const navigateToUserDeepLink = (id) => {
    window.open(`https://votiamo.com/userDetails/?id=${id}`, '_blank');
  };

  return (
    <React.Fragment>
      <Modal
        show={matchUserModal}
        onHide={matchUserToggle}
        backdrop="static"
        size="lg"
        centered
      >
        <ModalBody className='p-3'>
          <h4>Utenti che hanno risposto come te.</h4>
          {
            matchData && matchData?.length > 0 ?
              <Accordion open={open} toggle={toggle}>
                {
                  matchData.map((m, i) => (
                    <AccordionItem>
                      <AccordionHeader targetId={i + 1}><img src={m?.userDetails?.image ? `${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${m?.userDetails?.image}` : `${process.env.PUBLIC_URL}/images/no_profile.png`} className='rounded-circle' style={{ width: "40px", height: "40px" }} /><span style={{ marginLeft: "10px" }}>{m?.userDetails?.name}</span></AccordionHeader>
                      <AccordionBody accordionId={i + 1}>
                        <div style={{ width: "100%", height: "30px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <p></p>
                          <Button color='primary' disabled={m?.isFriend !== null} onClick={e => handleAddFriendBtn(m.createdBy)}>
                            {
                              m?.isFriend === null ? "Chiedi amicizia" : m?.isFriend === "Accepted" ? "Siete amici" : m?.isFriend === "Pending" && "In attesa"
                            }
                          </Button>
                        </div>
                        <code>
                          Nome: {m?.userDetails?.name} <br />
                          {m?.userDetails?.gender?.isVisible && <span style={{ display: "block" }}>Genere: {m?.userDetails?.gender?.name}</span>}
                          {/* {m?.userDetails?.dob?.isVisible && <span style={{ display: "block" }}>dob: {moment(new Date(m?.userDetails?.dob?.date)).format("DD/MM/YYYY")}</span>} */}
                          {m?.userDetails?.dob?.isVisible && <span style={{ display: "block" }}>Età: {_calculateAge(new Date(m?.userDetails?.dob?.date))}</span>}
                          {m?.userDetails?.education?.isVisible && <span style={{ display: "block" }}>Educazione: {m?.userDetails?.education?.name}</span>}
                          {m?.userDetails?.civilState?.isVisible && <span style={{ display: "block" }}>Stato familiare: {m?.userDetails?.civilState?.name}</span>}
                          {m?.userDetails?.nation_country?.isVisible && <span style={{ display: "block" }}>Paese: {m?.userDetails?.countryName[0] && m?.userDetails?.countryName[0]?.name}</span>}
                          {
                            m?.userDetails?.nation_country?.id === "107" ?
                              <React.Fragment>
                                {m?.userDetails?.region?.isVisible && <span style={{ display: "block" }}>Regione: {m?.userDetails?.regionName[0] && m?.userDetails?.regionName[0]?.DescrRegione}</span>}
                                {m?.userDetails?.provincia?.isVisible && <span style={{ display: "block" }}>Province: {m?.userDetails?.provinciaName[0] && m?.userDetails?.provinciaName[0]?.DescrProvincia}</span>}
                                {m?.userDetails?.comune?.isVisible && <span style={{ display: "block" }}>Comune: {m?.userDetails?.comuneName[0] && m?.userDetails?.comuneName[0]?.DescrComune}</span>}
                              </React.Fragment> :
                              <React.Fragment>
                                {m?.userDetails?.region_state?.isVisible && <span style={{ display: "block" }}>Stato: {m?.userDetails?.stateName[0] && m?.userDetails?.stateName[0]?.name}</span>}
                                {m?.userDetails?.city?.isVisible && <span style={{ display: "block" }}>Città: {m?.userDetails?.cityName[0] && m?.userDetails?.cityName[0]?.name}</span>}
                              </React.Fragment>
                          }
                          <span style={{ display: "block" }}>Account creato il: {moment(new Date(m?.createdAt)).format("DD/MM/YYYY H:m")}</span>
                        </code>
                        <a className='btn btn-success btn-block w-100 mt-3' onClick={e => navigateToUserDeepLink(m?.userDetails?._id)} target='_blank' rel="noopener noreferrer">Dettagli utente</a>
                      </AccordionBody>
                    </AccordionItem>
                  ))
                }
              </Accordion> :
              <h3 className='text-muted text-center mt-3'>Nessuno trovato.</h3>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={e => {
            matchUserToggle();
          }}>Ok</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default MatchUser;