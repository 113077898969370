export const privacyHtml = (logo) => {
  return (`
    <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Votiamo App - Privacy Policy</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            margin: 0;
            padding: 0;
            background-color: #f9f9f9;
            color: #333;
        }
        header {
            text-align: center;
            padding: 20px 0;
            background-color: #fff;
        }
        header img {
            width: 150px;
            height: auto;
        }
        header h1 {
            margin: 10px 0 0;
            font-size: 24px;
            color: #0073a6;
        }
        main {
            padding: 20px;
            max-width: 90%;
            margin: 20px auto;
        }
        h2 {
            color: #005580;
            margin-top: 20px;
        }
        h3 {
            color: #333;
            margin-top: 15px;
        }
        p {
            margin: 10px 0;
        }
        ul {
            margin: 10px 0 20px;
            padding-left: 20px;
        }
        footer {
            text-align: center;
            padding: 10px 0;
            font-size: 12px;
            color: #777;
        }
        .highlight {
            font-weight: bold;
            color: #0073a6;
        }
    </style>
</head>
<body>
    <header>
        <img src="${logo}" alt="Votiamo App Logo">
        <h1>Votiamo App - Privacy Policy</h1>
    </header>
    <main>
        <h2>Introduction</h2>
        <p>We wish to inform you that Il Cavedio Società Cooperativa, owner of the Votiamo brand, with legal and administrative headquarters at Via De Cristoforis 12, 21100 Varese (VA), in its capacity as Data Controller pursuant to EU Regulation 2016/679 (GDPR or Applicable Law), recognizes the importance of protecting personal data and considers its safeguarding one of the main objectives of its activities. In compliance with the Applicable Law, we provide you with the necessary information regarding the processing of personal data you have provided. This notice is given pursuant to the Applicable Law, and the Data Controller invites you to read it carefully as it contains important information about the protection of personal data and the security measures adopted to ensure confidentiality in full compliance with the Applicable Law. Il Cavedio Società Cooperativa informs you that the processing of personal data will be based on principles of lawfulness, correctness, transparency, purpose limitation and storage, data minimization, accuracy, integrity, and confidentiality. Therefore, personal data will be processed in accordance with the legislative provisions of the Applicable Law and the confidentiality obligations provided therein.</p>
        
        <h2>Definitions</h2>
        <ul>
            <li><span class="highlight">"Personal data"</span>: any information relating to an identified or identifiable natural person ("data subject"); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that natural person.</li>
            <li><span class="highlight">"Processing"</span>: any operation or set of operations performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure, or destruction.</li>
            <li><span class="highlight">"Health data"</span>: personal data related to the physical or mental health of a natural person, including the provision of health care services, which reveal information about their health status.</li>
        </ul>

        <h2>Place of Data Processing</h2>
        <p>Data processing takes place at the aforementioned headquarters of the Data Controller, at any operational sites (where courses are held), and at third parties identified by the Data Controller.</p>
        
        <h2>Types of Data Processed</h2>
        <p>The processing concerns personal and identification data provided by the data subject (for example but not limited to: name, surname, address, VAT number, tax code, landline or mobile phone number, email address, bank details, etc.).</p>

        <h2>Purposes, Legal Basis, and Mandatory or Optional Nature of Processing</h2>
        <p>The personal data provided will be processed by the Data Controller for the following purposes:</p>
        <h3>a) Administrative-accounting purposes</h3>
        <p>For the application of data protection provisions...</p>
        <h3>b) Informational and promotional purposes</h3>
        <p>The use of the provided email addresses is allowed...</p>

        <h2>Methods of Processing – Data Storage</h2>
        <p>The processing will be carried out in an automated...</p>

        <h2>Details on the Use of Data in the Mobile Application</h2>
        <p>At Votiamo, we are committed to protecting...</p>

        <h2>Rights of the Data Subject</h2>
        <p>We inform you that at any time you may exercise...</p>

        <p><strong>Date:</strong> September 1, 2024</p>
        <p><strong>Data Controller:</strong> Il Cavedio Società Cooperativa</p>
    </main>
    <footer>
        <p>&copy; 2024 Il Cavedio Società Cooperativa. All rights reserved.</p>
    </footer>
</body>
</html>
    `)
};