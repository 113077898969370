import React from 'react';
import MagazinePDF from "../static/magazine.pdf";

const Magazine = () => {
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <iframe
        src={MagazinePDF}
        title="Magazine"
        width="100%"
        height="100%"
        style={{ border: "none" }}
      ></iframe>
    </div>
  )
};

export default Magazine;