import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "react-bootstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Cell,
} from "recharts";

const Graph = ({ data, qData }) => {
    const [values, setValues] = useState([]);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    useEffect(() => {
        if (data && qData) {
            doSomething();
        }
    }, [data, qData]);

    const doSomething = () => {
        const obj = qData.options?.reduce((acc, option, index) => {
            acc[index + 1] = 0; // Use index + 1 as the key (1, 2, 3, etc.)
            return acc;
        }, {});

        data?.forEach((ans) => {
            const optionIndex = qData.options.indexOf(ans.surveyAnswer.answer) + 1; // Map option to index
            if (obj[optionIndex] !== undefined) {
                obj[optionIndex] += 1;
            }
        });

        const newArr = Object.entries(obj).map(([key, value]) => ({
            name: key, // Use the numeric key (1, 2, 3, etc.)
            Answer: value,
        }));

        setValues(newArr);
    };

    const maxPv = Math.max(...values.map((item) => item.Answer), 0);

    return (
        <>
            <div className="d-flex">
                <BarChart
                    width={500}
                    height={260}
                    data={values}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis allowDecimals={false} />
                    <Legend />
                    {/* <Tooltip /> */}
                    <Bar dataKey="Answer" barSize={10}>
                        {values.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={entry.Answer === maxPv ? "#20C6FB" : "#7E879F"}
                            />
                        ))}
                    </Bar>
                </BarChart>
            </div>
            {qData?.comment && (
                <Row className="mt-3 mb-3">
                    <Col>
                        <h6
                            onClick={toggleCollapse}
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            Commenti
                            <span style={{ cursor: "pointer", marginLeft: "10px" }}>
                                {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
                            </span>
                        </h6>
                        {!isCollapsed &&
                            data &&
                            data.length > 0 &&
                            data.map((elem, i) => (
                                elem?.surveyAnswer?.comment && (
                                    <Card style={{ marginBottom: "5px" }} key={i}>
                                        <CardBody>{elem?.surveyAnswer?.comment}</CardBody>
                                    </Card>
                                )
                            ))}
                    </Col>
                </Row>
            )}
        </>
    );
};

export default Graph;
