import React, { useEffect, useLayoutEffect, useState } from "react";
import Loader from "../loader/Loader";
import Navigation from "../components/Navigation";
import { useDispatch, useSelector } from "react-redux";
import "./Survey.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useNavigate } from "react-router-dom";
import * as surveyActions from "../redux/survey/survey.actions";
import moment from "moment";

const Survey = () => {

  const [isMobile, setIsMobile] = useState(false);
  const [isSurveyDDViewing, setIsSurveyDDViewing] = useState(false);
  const [surveyFilter, setSurveyFilter] = useState("All");
  const [data, setData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [innerLoading, setInnerLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.surveyInfo.loading);
  const list = useSelector((state) => state.surveyInfo.list);

  useEffect(() => {
    dispatch(surveyActions.getPublicSurveyList());
    scrollToTop();
  }, []);

  useEffect(() => {
    if (list && list.length > 0) {
      setData(list);
      setActiveData(list.filter(elem => elem.active));
      setCompletedData(list.filter(elem => !elem.active));
      setInnerLoading(false);
    } else {
      setData([]);
    }
  }, [list]);

  useEffect(() => {
    if (surveyFilter === "All") {
      setData(list);
    } else if (surveyFilter === "Active") {
      setData(list.filter(elem => elem.active));
    } else if (surveyFilter === "Completed") {
      setData(list.filter(elem => !elem.active));
    } else {
      setData(list);
    }
    scrollToTop();
  }, [surveyFilter]);

  useLayoutEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 575) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Navigation />
      {loading || innerLoading ? (
        <Loader />
      ) : (
        <>
          <section className="survey">
            <div className="container">
              <div className="row">
                {data && data.length > 0 ? (
                  <>
                    <div className="col-12 sticky_filter_section">
                      <span className="surveys_heading">Sondaggi</span>
                      {isMobile && (
                        <div className="survey_dropdown_container">
                          <div
                            onClick={() =>
                              setIsSurveyDDViewing(!isSurveyDDViewing)
                            }
                            className="survey_dropdown_btn"
                          >
                            Showing : <span>{surveyFilter}&nbsp;&nbsp;<span>{data.length}</span></span>
                          </div>
                          {isSurveyDDViewing && (
                            <div className="survey_dropdown">
                              <ul>
                                <li>
                                  <label htmlFor="flexCheckAll">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="flexCheckAll"
                                      name="surveyFilter"
                                      checked={surveyFilter === "All"}
                                      onChange={e => {
                                        setSurveyFilter("All");
                                        setIsSurveyDDViewing(!isSurveyDDViewing);
                                      }}
                                    />
                                    <span style={{ marginLeft: "5px" }}>Tutto</span>
                                  </label>
                                </li>
                                <li>
                                  <label htmlFor="flexCheckActive">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="flexCheckActive"
                                      name="surveyFilter"
                                      checked={surveyFilter === "Active"}
                                      onChange={e => {
                                        setSurveyFilter("Active");
                                        setIsSurveyDDViewing(!isSurveyDDViewing);
                                      }}
                                    />
                                    <span style={{ marginLeft: "5px" }}>Attivo</span>
                                  </label>
                                </li>
                                <li>
                                  <label htmlFor="flexCheckCompleted">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      id="flexCheckCompleted"
                                      name="surveyFilter"
                                      checked={surveyFilter === "Completed"}
                                      onChange={e => {
                                        setSurveyFilter("Completed");
                                        setIsSurveyDDViewing(!isSurveyDDViewing);
                                      }}
                                    />
                                    <span style={{ marginLeft: "5px" }}>Completato</span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <Tab.Container defaultActiveKey="survey_tab_all">
                      <div className="col-12">
                        <div className="row mb-5">
                          {!isMobile && (
                            <div className="col-xl-3 col-lg-4 mt-4 surveys_left_column_wrapper">
                              <Nav
                                variant="pills"
                                className="flex-column surveys_left_column"
                              >
                                <Nav.Item onClick={e => scrollToTop()}>
                                  <Nav.Link eventKey="survey_tab_all">
                                    Tutto {isMobile && <>Sondaggio</>}
                                    <span>{data.length}</span>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={e => scrollToTop()}>
                                  <Nav.Link eventKey="survey_tab_active">
                                    Attivo {isMobile && <>Sondaggio</>}
                                    <span>{activeData.length}</span>
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={e => scrollToTop()}>
                                  <Nav.Link eventKey="survey_tab_completed">
                                    Completato {isMobile && <>Sondaggio</>}
                                    <span>{completedData.length}</span>
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </div>
                          )}
                          <div className="col-xl-9 col-lg-8 mt-4">
                            <Tab.Content className="surveys_right_column">
                              <Tab.Pane eventKey="survey_tab_all">
                                <div className="row g-3">
                                  {
                                    data && data.length > 0 ? data.map(elem => (
                                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={elem._id}>
                                        <div onClick={() => navigate(`/survey-details/${elem._id}`)} className="survey_card">
                                          <img
                                            src={elem?.banner ? `${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${elem.banner}` : `${process.env.PUBLIC_URL}/images/votiamo_mini.png`}
                                            className="w-100 h-100 object-fit-cover"
                                          />
                                          <span className={`survey_card_status ${elem.active ? "survey_card_active" : "survey_card_completed"}`}>
                                            {elem.active ? "Attivo" : "Completato"}
                                          </span>
                                          <div className="survey_card_content">
                                            <h5 className="survey_card_title">
                                              {elem?.title}
                                            </h5>
                                            <div className="survey_card_extra">
                                              <span>
                                                <img
                                                  src={`${process.env.PUBLIC_URL}/images/survey/calendarIcon.png`}
                                                />
                                                {moment(new Date(elem?.createdAt)).format("DD MMM, YY")}
                                              </span>
                                              <span>
                                                <img
                                                  src={`${process.env.PUBLIC_URL}/images/survey/peopleIcon.png`}
                                                />
                                                {elem?.attendedUsers} Tentato
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )) :
                                      <h2 className="text-muted text-center mt-3">No Data Found</h2>
                                  }
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="survey_tab_active">
                                <div className="row g-3">
                                  {
                                    activeData && activeData.length > 0 ? activeData.map(elem => (
                                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={elem._id}>
                                        <div className="survey_card" onClick={() => navigate(`/survey-details/${elem._id}`)}>
                                          <img
                                            src={elem?.banner ? `${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${elem.banner}` : `${process.env.PUBLIC_URL}/images/votiamo_mini.png`}
                                            className="w-100 h-100 object-fit-cover"
                                          />
                                          <span className="survey_card_status survey_card_active">
                                            Attivo
                                          </span>
                                          <div className="survey_card_content">
                                            <h5 className="survey_card_title">
                                              {elem?.title}
                                            </h5>
                                            <div className="survey_card_extra">
                                              <span>
                                                <img
                                                  src={`${process.env.PUBLIC_URL}/images/survey/calendarIcon.png`}
                                                />
                                                {moment(new Date(elem?.createdAt)).format("DD MMM, YY")}
                                              </span>
                                              <span>
                                                <img
                                                  src={`${process.env.PUBLIC_URL}/images/survey/peopleIcon.png`}
                                                />
                                                {elem?.attendedUsers} Tentato
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )) :
                                      <h2 className="text-muted text-center mt-3">No Data Found</h2>
                                  }
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="survey_tab_completed">
                                <div className="row g-3">
                                  {
                                    completedData && completedData.length > 0 ? completedData.map(elem => (
                                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={elem._id}>
                                        <div className="survey_card" onClick={() => navigate(`/survey-details/${elem._id}`)}>
                                          <img
                                            src={elem?.banner ? `${process.env.REACT_APP_AWS_BUCKET_BASE_URL}${elem.banner}` : `${process.env.PUBLIC_URL}/images/votiamo_mini.png`}
                                            className="w-100 h-100 object-fit-cover"
                                          />
                                          <span className="survey_card_status survey_card_completed">
                                            Completato
                                          </span>
                                          <div className="survey_card_content">
                                            <h5 className="survey_card_title">
                                              {elem?.title}
                                            </h5>
                                            <div className="survey_card_extra">
                                              <span>
                                                <img
                                                  src={`${process.env.PUBLIC_URL}/images/survey/calendarIcon.png`}
                                                />
                                                {moment(new Date(elem?.createdAt)).format("DD MMM, YY")}
                                              </span>
                                              <span>
                                                <img
                                                  src={`${process.env.PUBLIC_URL}/images/survey/peopleIcon.png`}
                                                />
                                                {elem?.attendedUsers} Tentato
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )) :
                                      <h2 className="text-muted text-center mt-3">No Data Found</h2>
                                  }
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </div>
                        </div>
                      </div>
                    </Tab.Container>
                  </>
                ) : (
                  <React.Fragment>
                    <h2 className="text-muted text-center mt-3">Not Found</h2>
                  </React.Fragment>
                )}
              </div>
            </div>
          </section>
        </>
      )
      }
    </>
  );
};

export default Survey;