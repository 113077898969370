export const supportHtml = (logo) => {
  return (`
<!DOCTYPE html>
<html>
<head>
	<title>Support</title>
	 <!-- Latest compiled and minified CSS -->
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">

<!-- jQuery library -->
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

<!-- Popper JS -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

<!-- Latest compiled JavaScript -->
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script> 
	<style type="text/css">
		img{
			height: 150px;
			width: 150px;
			margin-left: 337px;
		}
		p{
		 al: center;
		}
		
	</style>
</head>
<body>
	<section class="header_area">
    <div class="header_navbar">
            <div class="row">
                <div class="col-lg-12">
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand">
                            <img src="${logo}" alt="Logo">
                        </a></nav>
					</div>
                    </div>
                    </div>
                    </section>
    <div class="container">
				<p><b style="font-size: 28px;"><center>Supporto Votiamo</center></b></p>
				<p style="font-size: 23px;"><b>Supporto</b></p>
				<p>Per qualsiasi richiesta di supporto, non esitate a contattarci su : </p>
<p dir="ltr" class="CDt4Ke zfr3Q" style="background-color: transparent; border-bottom: none; border-left: none; border-right: none; border-top: none; line-height: 2.1; margin-bottom: 0; margin-top: 11pt; padding-bottom: 0; padding-left: 0; padding-right: 0; padding-top: 0;"><span class=" aw5Odc" style="font-family: 'Arial'; font-size: 12pt; text-decoration: underline; vertical-align: baseline;"><a class="XqQF9c" href="mailto:support@votiamo.com" target="_blank"><strong>support@votiamo.com</strong></a></span><span style="color: #212121; font-family: 'Arial'; font-size: 12pt; font-weight: normal; vertical-align: baseline;"></p>
	</div>
</body>
</html>
    `)
};