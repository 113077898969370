import React from "react";
import Home from "./components/Home";
import { Routes, Route } from "react-router-dom";
import Survey from "./pages/Survey";
import SurveyDetails from "./pages/SurveyDetails";
import SurveyResult from "./pages/SurveyResult";
import ScrollToAnchor from "./components/ScrollToAnchor";
import Error404 from "./errorsPage/404Error/Error404";
import Redirect from "./errorsPage/404Error/Redirect";
import Terms from "./pages/Terms";
import TermsIt from "./pages/TermsIt";
import Privacy from "./pages/Privacy";
import PrivacyIt from "./pages/PrivacyIt";
import Support from "./pages/Support";
import SupportIt from "./pages/SupportIt";
import ContestRules from "./pages/ContestRules";
import Magazine from "./pages/Magazine";

const App = () => {
  return (
    <React.Fragment>
      <ScrollToAnchor />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/survey' element={<Survey />} />
        <Route path='/survey-details/:id' element={<SurveyDetails />} />
        <Route path='/survey-results/:id' element={<SurveyResult />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/terms/it' element={<TermsIt />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/privacy/it' element={<PrivacyIt />} />
        <Route path='/support' element={<Support />} />
        <Route path='/support/it' element={<SupportIt />} />
        <Route path='/regolamento' element={<ContestRules />} />
        <Route path='/magazine' element={<Magazine />} />
        <Route path='*' element={<Redirect />} />
      </Routes>
    </React.Fragment>
  )
};

export default App;