import axios from 'axios';
import { toast } from 'react-toastify';

export const GET_PUBLIC_SURVEY_REQUEST = 'GET_PUBLIC_SURVEY_REQUEST';
export const GET_PUBLIC_SURVEY_SUCCESS = 'GET_PUBLIC_SURVEY_SUCCESS';
export const GET_PUBLIC_SURVEY_FAILURE = 'GET_PUBLIC_SURVEY_FAILURE';

export const GET_PUBLIC_SURVEY_DETAILS_REQUEST = 'GET_PUBLIC_SURVEY_DETAILS_REQUEST';
export const GET_PUBLIC_SURVEY_DETAILS_SUCCESS = 'GET_PUBLIC_SURVEY_DETAILS_SUCCESS';
export const GET_PUBLIC_SURVEY_DETAILS_FAILURE = 'GET_PUBLIC_SURVEY_DETAILS_FAILURE';

export const GET_SURVEY_QUESTIONS_REQUEST = 'GET_SURVEY_QUESTIONS_REQUEST';
export const GET_SURVEY_QUESTIONS_SUCCESS = 'GET_SURVEY_QUESTIONS_SUCCESS';
export const GET_SURVEY_QUESTIONS_FAILURE = 'GET_SURVEY_QUESTIONS_FAILURE';

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_REQUEST';
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE';

export const GET_STATE_REQUEST = 'GET_STATE_REQUEST';
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS';
export const GET_STATE_FAILURE = 'GET_STATE_FAILURE';

export const GET_CITY_REQUEST = 'GET_CITY_REQUEST';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_FAILURE = 'GET_CITY_FAILURE';

export const GET_REGION_LIST_REQUEST = 'GET_REGION_LIST_REQUEST';
export const GET_REGION_LIST_SUCCESS = 'GET_REGION_LIST_SUCCESS';
export const GET_REGION_LIST_FAILURE = 'GET_REGION_LIST_FAILURE';

export const GET_PROVINCIA_LIST_REQUEST = 'GET_PROVINCIA_LIST_REQUEST';
export const GET_PROVINCIA_LIST_SUCCESS = 'GET_PROVINCIA_LIST_SUCCESS';
export const GET_PROVINCIA_LIST_FAILURE = 'GET_PROVINCIA_LIST_FAILURE';

export const GET_COMUNE_LIST_REQUEST = 'GET_COMUNE_LIST_REQUEST';
export const GET_COMUNE_LIST_SUCCESS = 'GET_COMUNE_LIST_SUCCESS';
export const GET_COMUNE_LIST_FAILURE = 'GET_COMUNE_LIST_FAILURE';

export const GET_SURVEY_RESULT_REQUEST = 'GET_SURVEY_RESULT_REQUEST';
export const GET_SURVEY_RESULT_SUCCESS = 'GET_SURVEY_RESULT_SUCCESS';
export const GET_SURVEY_RESULT_FAILURE = 'GET_SURVEY_RESULT_FAILURE';

export const GET_CIVIL_STATE_TYPE_LIST_REQUEST = 'GET_CIVIL_STATE_TYPE_LIST_REQUEST';
export const GET_CIVIL_STATE_TYPE_LIST_SUCCESS = 'GET_CIVIL_STATE_TYPE_LIST_SUCCESS';
export const GET_CIVIL_STATE_TYPE_LIST_FAILURE = 'GET_CIVIL_STATE_TYPE_LIST_FAILURE';

export const GET_EDUCATION_TYPE_LIST_REQUEST = 'GET_EDUCATION_TYPE_LIST_REQUEST';
export const GET_EDUCATION_TYPE_LIST_SUCCESS = 'GET_EDUCATION_TYPE_LIST_SUCCESS';
export const GET_EDUCATION_TYPE_LIST_FAILURE = 'GET_EDUCATION_TYPE_LIST_FAILURE';

export const SEND_FRIEND_REQUEST_REQUEST = 'SEND_FRIEND_REQUEST_REQUEST';
export const SEND_FRIEND_REQUEST_SUCCESS = 'SEND_FRIEND_REQUEST_SUCCESS';
export const SEND_FRIEND_REQUEST_FAILURE = 'SEND_FRIEND_REQUEST_FAILURE';

export const getPublicSurveyList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PUBLIC_SURVEY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/public/survey/list/v2`;
            const res = await axios.get(url);
            dispatch({ type: GET_PUBLIC_SURVEY_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PUBLIC_SURVEY_FAILURE, payload: error.message });
        }
    }
};

export const getPublicSurveyDetails = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PUBLIC_SURVEY_DETAILS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/public/survey/details/${id}/v2`;
            const res = await axios.get(url);
            dispatch({ type: GET_PUBLIC_SURVEY_DETAILS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PUBLIC_SURVEY_DETAILS_FAILURE, payload: error.message });
        }
    }
};

export const getSurveyQuestions = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_SURVEY_QUESTIONS_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/survey/questions/${id}/v2`;
            const res = await axios.get(url);
            dispatch({ type: GET_SURVEY_QUESTIONS_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_SURVEY_QUESTIONS_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getCountryList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_COUNTRY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/country/code/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_COUNTRY_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_COUNTRY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getStateList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_STATE_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/state/list/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_STATE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_STATE_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getCityList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_CITY_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/city/list/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_CITY_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_CITY_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getRegionList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_REGION_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/italy/region/list/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_REGION_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_REGION_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getProvinciaList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_PROVINCIA_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/italy/provincia/list/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_PROVINCIA_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_PROVINCIA_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getComuneList = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_COMUNE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/italy/comune/list/${id}/v1`;
            const res = await axios.get(url);
            dispatch({ type: GET_COMUNE_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_COMUNE_LIST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const getSurveyResult = (id, question, gender, minage, maxage, country, region, city, education, civil, italy_region, proviancia, comune, body) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_SURVEY_RESULT_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/survey/result/${id}/${question}/v2?gender=${gender}&minage=${minage}=&maxage=${maxage}&country=${country}&region=${region}&city=${city}&education=${education}&civil=${civil}&italy_region=${italy_region}&proviancia=${proviancia}&comune=${comune}&userId=`;
            const res = await axios.put(url, body);
            dispatch({ type: GET_SURVEY_RESULT_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_SURVEY_RESULT_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};

export const civilStateTypeList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_CIVIL_STATE_TYPE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/civil/state/type/list/v1`;
            const response = await axios.get(url);
            dispatch({ type: GET_CIVIL_STATE_TYPE_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_CIVIL_STATE_TYPE_LIST_SUCCESS, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const educationTypeList = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_EDUCATION_TYPE_LIST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/education/type/list/v1`;
            const response = await axios.get(url);
            dispatch({ type: GET_EDUCATION_TYPE_LIST_SUCCESS, payload: response.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_EDUCATION_TYPE_LIST_SUCCESS, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const sendFriendRequest = (id, userId, setData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SEND_FRIEND_REQUEST_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/user/friend/request/result/side/${id}/${userId}/v1`;
            const res = await axios.put(url, {});
            toast("Richiesta di amicizia inviata.");
            dispatch({ type: SEND_FRIEND_REQUEST_SUCCESS, payload: res.data });
            setData(id);
        } catch (error) {
            console.error(error);
            dispatch({ type: SEND_FRIEND_REQUEST_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    }
};