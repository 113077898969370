export const contentRulesHtml = () => {
    return (`
    <!DOCTYPE html>
<html lang="it">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Regolamento Concorso a Premi - Votiamo</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            margin: 0;
            padding: 0;
            background-color: #f9f9f9;
            color: #333;
        }
        header {
            text-align: center;
            padding: 20px 0;
            background-color: #fff;
        }
        header h1 {
            margin: 10px 0 0;
            font-size: 24px;
            color: #0073a6;
        }
        main {
            padding: 20px;
            max-width: 90%;
            margin: 20px auto;
        }
        h2 {
            color: #005580;
            margin-top: 20px;
        }
        h3 {
            color: #333;
            margin-top: 15px;
        }
        p, ul {
            margin: 10px 0;
        }
        ul {
            padding-left: 20px;
        }
        footer {
            text-align: center;
            padding: 10px 0;
            font-size: 12px;
            color: #777;
        }
    </style>
</head>
<body>
    <header>
        <h1>Regolamento del Concorso a Premi</h1>
        <p>Partecipazione ai Sondaggi sull'App "Votiamo"</p>
    </header>
    <main>
        <h2>1. Soggetto promotore</h2>
        <p>Il concorso è organizzato da <strong>Il Cavedio società cooperativa</strong>, per promuovere la partecipazione ai sondaggi sulla piattaforma "Votiamo".</p>

        <h2>2. Modalità di partecipazione</h2>
        <p>La partecipazione al concorso è riservata esclusivamente agli utenti che:</p>
        <ul>
            <li>Utilizzano l'app "Votiamo" per partecipare ai sondaggi.</li>
            <li>Partecipano al sondaggio in modalità pubblica (escludendo le modalità anonima o privata).</li>
        </ul>

        <h2>3. Periodo e modalità di estrazione</h2>
        <p>L’estrazione avverrà alla data di fine del sondaggio relativo, indicata sull’app. La selezione dei vincitori sarà effettuata automaticamente tramite il software dell'app, utilizzando una modalità randomica.</p>

        <h2>4. Premi in palio</h2>
        <p>Saranno messi in palio 4 premi, uno per ciascun sondaggio. I premi sono forniti dagli sponsor del sondaggio relativo. I dettagli relativi ai premi, le indicazioni per utilizzarli e le date di scadenza sono specificati nelle descrizioni dei premi dei sondaggi relativi.</p>

        <h2>5. Comunicazione dei vincitori</h2>
        <p>I vincitori riceveranno la comunicazione della vincita direttamente tramite l’app "Votiamo" e via e-mail.</p>

        <h2>6. Modalità di riscossione del premio</h2>
        <p>I vincitori, una volta ricevuto l’avviso di vincita, dovranno contattare l’organizzatore seguendo le istruzioni fornite nell’avviso stesso per ricevere il premio.</p>

        <h2>7. Note generali</h2>
        <ul>
            <li>La partecipazione al concorso è gratuita, salvo eventuali costi di connessione a carico dell'utente per l'accesso all'app "Votiamo".</li>
            <li>I premi non sono convertibili in denaro.</li>
            <li>La partecipazione implica l’accettazione integrale del presente regolamento.</li>
        </ul>

        <h2>Dettagli specifici per l'edizione novembre 2024</h2>
        <h3>Periodo e modalità di estrazione</h3>
        <p>L’estrazione avverrà alla fine dei sondaggi, in data <strong>31 gennaio 2025</strong>. La selezione dei vincitori sarà effettuata automaticamente tramite il software dell'app, utilizzando una modalità randomica.</p>

        <h3>Premi in palio</h3>
        <p>Saranno messi in palio 4 premi, uno per ciascun sondaggio. Ogni premio consiste in un <strong>buono sconto del valore di 50 €</strong> da utilizzare per i corsi disponibili su <a href="https://www.portalecorsi.com/" target="_blank">portalecorsi.com</a>, organizzati da Il Cavedio. I buoni sconto saranno validi fino al <strong>30 marzo 2025</strong>.</p>
    </main>
    <footer>
        <p>&copy; 2024 Il Cavedio Società Cooperativa. Tutti i diritti riservati.</p>
    </footer>
</body>
</html>
`)
};