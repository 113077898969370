export const privacyHtml = (logo) => {
    return (`
    <!DOCTYPE html>
<html lang="it">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Votiamo App - Privacy Policy</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            margin: 0;
            padding: 0;
            background-color: #f9f9f9;
            color: #333;
        }
        header {
            text-align: center;
            padding: 20px 0;
            background-color: #fff;
        }
        header img {
            width: 150px;
            height: auto;
        }
        header h1 {
            margin: 10px 0 0;
            font-size: 24px;
            color: #0073a6;
        }
        main {
            padding: 20px;
            max-width: 90%;
            margin: 20px auto;
        }
        h2 {
            color: #005580;
            margin-top: 20px;
        }
        h3 {
            color: #333;
            margin-top: 15px;
        }
        p {
            margin: 10px 0;
        }
        ul {
            margin: 10px 0 20px;
            padding-left: 20px;
        }
        footer {
            text-align: center;
            padding: 10px 0;
            font-size: 12px;
            color: #777;
        }
        .highlight {
            font-weight: bold;
            color: #0073a6;
        }
    </style>
</head>
<body>
    <header>
        <img src="${logo}" alt="Votiamo App Logo">
        <h1>Votiamo App - Informativa sulla privacy</h1>
    </header>
    <main>
        <h2>Introduzione</h2>
        <p>Desideriamo informarLa che Il Cavedio Società Cooperativa, titolare del marchio Votiamo, con sede legale e amministrativa in Via De Cristoforis 12, 21100 Varese (VA), in qualità di Titolare del trattamento dei dati personali ai sensi del Regolamento UE 2016/679 (RGPD o GDPR o Normativa Applicabile), riconosce l’importanza della protezione dei dati personali e considera la sua tutela uno degli obiettivi principali della propria attività. In osservanza della Normativa Applicabile, siamo a fornirLe le dovute informazioni in ordine al trattamento dei dati personali da Lei forniti. Si tratta di un'informativa resa ai sensi della Normativa Applicabile, che il Titolare del trattamento La invita a leggere con attenzione, poiché contiene informazioni importanti sulla tutela dei dati personali e sulle misure di sicurezza adottate per garantirne la riservatezza nel pieno rispetto della Normativa Applicabile.</p>
        <p>Il Cavedio Società Cooperativa La informa che il trattamento dei dati personali sarà improntato ai principi di liceità, correttezza, trasparenza, limitazione delle finalità e della conservazione, minimizzazione dei dati, esattezza, integrità e riservatezza. I dati personali verranno pertanto trattati in accordo alle disposizioni legislative della Normativa Applicabile e agli obblighi di riservatezza ivi previsti.</p>
        
        <h2>Definizioni</h2>
        <ul>
            <li><span class="highlight">"Dato personale"</span>: qualsiasi informazione riguardante una persona fisica identificata o identificabile ("interessato"); si considera identificabile la persona fisica che può essere identificata, direttamente o indirettamente, con particolare riferimento a un identificativo come il nome, un numero di identificazione, dati relativi all'ubicazione, un identificativo online o a uno o più elementi caratteristici della sua identità fisica, fisiologica, genetica, psichica, economica, culturale o sociale.</li>
            <li><span class="highlight">"Trattamento"</span>: qualsiasi operazione o insieme di operazioni, compiute con o senza l'ausilio di processi automatizzati e applicate a dati personali o insiemi di dati personali, come la raccolta, la registrazione, l'organizzazione, la strutturazione, la conservazione, l'adattamento o la modifica, l'estrazione, la consultazione, l'uso, la comunicazione mediante trasmissione, diffusione o qualsiasi altra forma di messa a disposizione, il raffronto o l'interconnessione, la limitazione, la cancellazione o la distruzione.</li>
            <li><span class="highlight">"Dati relativi alla salute"</span>: i dati personali attinenti alla salute fisica o mentale di una persona fisica, compresa la prestazione di servizi di assistenza sanitaria, che rivelano informazioni relative al suo stato di salute.</li>
        </ul>

        <h2>Luogo di Trattamento dei Dati</h2>
        <p>I trattamenti dei dati hanno luogo presso la predetta sede del Titolare, presso le eventuali sedi operative (dove si svolgono i corsi) e presso soggetti terzi individuati dal Titolare.</p>

        <h2>Tipi di Dati Trattati</h2>
        <p>Il trattamento ha ad oggetto i dati personali e identificativi forniti dall’interessato (a titolo esemplificativo ma non esaustivo): nome, cognome, indirizzo, partita IVA, codice fiscale, numero di telefono fisso o mobile, indirizzo di posta elettronica, coordinate bancarie, altri dati pertinenti.</p>

        <h2>Finalità, Base Giuridica e Natura Obbligatoria o Facoltativa del Trattamento</h2>
        <p>I dati personali forniti saranno oggetto di trattamento da parte del Titolare per le seguenti finalità:</p>
        <h3>a) Amministrativo-contabili</h3>
        <p>Ai fini dell'applicazione delle disposizioni in materia di protezione dei dati personali...</p>
        <h3>b) Informative e promozionali</h3>
        <p>L’utilizzo delle coordinate di posta elettronica fornite è consentito ai fini di invio di informative e newsletter...</p>

        <h2>Modalità del Trattamento – Conservazione dei Dati</h2>
        <p>Il trattamento sarà svolto in forma automatizzata e manuale, con modalità e strumenti volti a garantire la massima sicurezza e riservatezza, ad opera di soggetti nominati responsabili ed addetti del trattamento ai sensi della normativa applicabile...</p>

        <h2>Dettagli sull’Utilizzo dei Dati nell’Applicazione Mobile</h2>
        <p>In Votiamo, ci impegniamo a proteggere la tua privacy e a garantire la sicurezza dei tuoi dati personali. Questa sezione spiega come raccogliamo, utilizziamo e salvaguardiamo le tue informazioni all'interno della nostra app...</p>

        <h2>Diritti dell’Interessato</h2>
        <p>La informiamo infine che in ogni momento potrà esercitare i diritti nei confronti del Titolare del trattamento ai sensi della Normativa Applicabile, quindi ottenere:</p>
        <ul>
            <li>La conferma dell’esistenza o meno dei medesimi dati</li>
            <li>Conoscerne il contenuto e l’origine</li>
            <li>Verificarne l’esattezza</li>
            <li>Chiederne l’integrazione, l’aggiornamento oppure la rettificazione</li>
            <li>Al ricorrere delle condizioni previste dalla Normativa Applicabile, Lei ha il diritto di chiedere:</li>
            <li>La rettifica e la cancellazione</li>
            <li>La limitazione di trattamento</li>
            <li>La portabilità dei dati</li>
            <li>Di opporsi, per motivi legittimi, al loro trattamento</li>
        </ul>

        <p>Le richieste vanno rivolte via e-mail all’indirizzo del Responsabile del Trattamento: info@ilcavedio.coop</p>

        <p><strong>Varese, 1° settembre 2024</strong></p>
        <p><strong>Il Titolare del trattamento:</strong> Il Cavedio Società Cooperativa</p>
    </main>
    <footer>
        <p>&copy; 2024 Il Cavedio Società Cooperativa. Tutti i diritti riservati.</p>
    </footer>
</body>
</html>

    `)
};